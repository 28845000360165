<template>
  <nav class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
        <router-link
          class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
          to="/"
        >
        <img
          v-if="logo"
          :src="logo"
          alt="Logo"
          class="h-12"
        />
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          @click="toggleNavbar"
        >
          <i class="text-white fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none"
        :class="[navbarOpen ? 'block rounded shadow-lg' : 'hidden']"
      >
        <ul class="flex flex-col lg:flex-row list-none mr-auto">
          <li class="flex items-center"></li>
        </ul>
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li
            v-for="item in menuItems"
            :key="item._id"
            class="flex items-center"
          >
            <router-link
              :to="item.href"
              class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              {{ item.Title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
import logo from '../../assets/logo/FS-icon.png';

export default {
data() {
  return {
    navbarOpen: false,
    menuItems: [],
    logo
  };
},
methods: {
  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  },
  async fetchMenuItems() {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_CONTENT_URL}Navbar`,
        {
          headers: {
            "api-key": process.env.VUE_APP_API_KEY,
          },
        }
      );
      this.menuItems = response.data.sort((a, b) => a.Order - b.Order);
    } catch (error) {
      console.error("Error fetching menu items:", error);
    }
  },
},
created() {
  this.fetchMenuItems();
},

};
</script>
