<template>
      <section id="about" class="relative py-20">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
              />
            </div>
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              <div class="md:pr-12">
                <div
                  class="text-fs-blue p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-fs-blue "
                >
                  <i class="fas fa-rocket text-xl"></i>
                </div>
                <h3 class="text-3xl font-semibold">{{ aboutTitle }}</h3>
                <p class="mt-4 text-lg leading-relaxed text-slate-500">
                  {{ aboutDescription }}
                </p>
                <ul class="list-none mt-6">
                  <li class="py-2" v-for="point in bulletPoints" :key="point.title">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-fs-blue bg-fs-blue mr-3"
                        >
                          <i :class="point.icon"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-slate-500">
                          {{ point.title }}
                        </h4>
                      </div>
                    </div>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
</template>

<script>
import axios from "axios";

export default {
  name: 'ServicesSection',
  data() {
    return {
      aboutTitle: null,
      aboutDescription: null,
      bulletPoints: []
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_ITEM_URL}about`,
          {
            headers: {
              "api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        const about = response.data;
        this.aboutTitle = about.title;
        this.aboutDescription = about.description;
        this.bulletPoints = about.bulletPoints;
      } catch (error) {
        console.error("Error fetching About item:", error);
      }
    },
  },
  created() {
    this.fetchData();
  }
};
</script>