<template>
      <section class="pb-20 relative block bg-slate-800">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0)"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-slate-800 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div class="flex flex-wrap text-center justify-center">
            <div class="w-full lg:w-6/12 px-4">
              <h2 class="text-4xl font-semibold text-white">{{ infoTitle }}</h2>
              <p class="text-lg leading-relaxed mt-4 mb-4 text-slate-400">
                {{ infoDescription }}
              </p>
            </div>
          </div>
          <div class="flex flex-wrap mt-12 justify-center">
            <div class="w-full lg:w-3/12 px-4 text-center" v-for="point in bulletPoints" :key="point.title">
              <div
                class="text-slate-800 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center"
              >
                <i :class=point.icon></i>
              </div>
              <h6 class="text-xl mt-5 font-semibold text-white">
                {{ point.title }}
              </h6>
              <p class="mt-2 mb-4 text-slate-400">
                {{ point.description }}
              </p>
            </div>

          </div>
        </div>
      </section>
</template>

<script>
import axios from "axios";

export default {
  name: 'ServicesSection',
  data() {
    return {
      infoTitle: null,
      infoDescription: null,
      bulletPoints: []
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_ITEM_URL}info`,
          {
            headers: {
              "api-key": process.env.VUE_APP_API_KEY,
            },
          }
        );
        const info = response.data;
        this.infoTitle = info.title;
        this.infoDescription = info.description;
        this.bulletPoints = info.bulletPoints;

      } catch (error) {
        console.error("Error fetching info item:", error);
      }
    },
  },
  created() {
    this.fetchData();
  }
};
</script>