<template>
  <div>
    <navbar />
    <main>
      <Hero />
      <Services />
      <About />
      <Info />
      <Contact />
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/LayoutNavbar.vue";
import Hero from "@/components/Hero/Hero.vue";
import Services from "@/components/Services/Services.vue";
import About from "@/components/About/About.vue";
import Info from "@/components/Info/Info.vue";
import Contact from "@/components/Contact/Contact.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    Navbar,
    Hero,
    Services,
    About,
    Info,
    Contact,
    FooterComponent,
  },
};
</script>
